import { Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {
  DsHeaderModule, DsBoxModule, DsButtonModule, DsNavigationBarModule, DsHeaderTagConfiguration, DsNavigationItem
} from '@bmw-ds/components';
import { environment } from '../environments/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import debug from 'debug';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    DsHeaderModule,
    DsBoxModule,
    DsButtonModule,
    DsNavigationBarModule,

  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  private logDebug = debug('app:AppComponent:log');
  private logError = debug('app:AppComponent:error*');

  //Tag with environment for header
  environmentTagConfig: Partial<DsHeaderTagConfiguration> = { label: environment.environmentName };
  userInfo = {
    username: 'John Doe',
    department: 'Dept 1',
    profileImg: 'https://www.gravatar.com/avatar/?d=identicon'
  };


  //Navigation configuration
  navigationItems: DsNavigationItem[] = [
    {
      label: 'Asset',
      routerLink: '/asset',
    },
  ];

  title = 'Asset Table';

  private oidcSecurityService = inject(OidcSecurityService);

  constructor() {
    this.logDebug.log = console.log.bind(console);
    this.logError.log = console.error.bind(console);
  }

  public async ngOnInit(): Promise<void> {
    if (environment.authEnabled) {
      this.logDebug("Checking auth");


      this.oidcSecurityService.checkAuth().subscribe(({ userData }) => {
        this.logDebug("Login succeeded: %O", userData);
        if (userData) {
          const { name, department, profileImg } = userData;
          if (name) this.userInfo.username = name;
          if (department) this.userInfo.department = department;
          if (profileImg) this.userInfo.profileImg = profileImg;
          
        } else {
          this.logDebug("Starting authorization");
          this.oidcSecurityService.authorize();
        }
      });
    }
  }
}
