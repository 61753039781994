import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, inject, signal } from '@angular/core';
import { DsButtonModule, DsToastService } from '@bmw-ds/components';
import { Order, OrderService } from '@bmw-spp/bmw-spp-frontend-common';

@Component({
  selector: 'app-dispatch-cell-button-renderer',
  standalone: true,
  imports: [
    DsButtonModule,
  ],
  templateUrl: './dispatch-cell-button-renderer.component.html',
  styleUrl: './dispatch-cell-button-renderer.component.scss'
})
export class DispatchCellButtonRendererComponent implements ICellRendererAngularComp {
  public params = signal<ICellRendererParams<Order> | undefined>(undefined);
  private orderService = inject(OrderService);
  toastService = inject(DsToastService);

  public agInit(params: ICellRendererParams<Order>): void {
    this.params.set(params);
  }

  public refresh(params: ICellRendererParams<Order>): boolean {
    this.params.set(params);
    return true;
  }

  public async clickHandler(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    try {

      await this.orderService.createItem(<Order>this.params()?.data);
      this.toastService.pushToast({
        id: 'success-toast',
        tone: 'positive',
        toastText: 'Änderungen gespeichert'
      });

    } catch (err) {
      this.toastService.pushToast({
        id: 'error-toast',
        tone: 'critical',
        toastText: 'Fehler beim Speichern.'
      });
    }


  }

}
