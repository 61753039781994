import { Component, computed, inject, signal } from '@angular/core';
import { DsIconModule, DsTagModule } from '@bmw-ds/components';
import { CommonModule } from '@angular/common';
import { DsTagPracticalTones } from '@bmw-ds/components/ds-interfaces/tag/tag.interface';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Order, OrderFunctionsService, OrderStatus } from '@bmw-spp/bmw-spp-frontend-common';

@Component({
  selector: 'app-order-status-cell-renderer',
  standalone: true,
  imports: [
    CommonModule,
    DsIconModule,
    DsTagModule,
  ],
  templateUrl: './order-status-cell-renderer.component.html',
  styleUrl: './order-status-cell-renderer.component.scss'
})
export class OrderStatusCellRendererComponent implements ICellRendererAngularComp {
  params = signal<ICellRendererParams<Order>|undefined>(undefined);

  public orderStatusEnum = OrderStatus;
  private orderFunctionService = inject(OrderFunctionsService);

  agInit(params: ICellRendererParams<Order>): void {
    this.params.set(params);    
  }

  refresh(params: ICellRendererParams<Order>): boolean {
    this.params.set(params);
    return true;
  }

  public status = computed(() => {
    const data = this.params()?.data;
    if (!data) {
      return undefined;
    }
    return this.orderFunctionService.getStatus(data)
  });

  public totalJobCount = computed(() => {
    const data = this.params()?.data;
    if (!data) {
      return undefined;
    }
    return this.orderFunctionService.getTotalJobCount(data)
  });

  public assignedJobCount = computed(() => {
    const data = this.params()?.data;
    if (!data) {
      return undefined;
    }
    return this.orderFunctionService.getAssignedToMechanicJobCount(data)
  });

  public tone = computed<DsTagPracticalTones>(() => {
    switch(this.status()) {
      case OrderStatus.PENDING:
        return "positive";
      case OrderStatus.COMPLETED:
        return "muted";
      case OrderStatus.READY:
        return "info";
      default:
        return "muted";
    }
  });
}
