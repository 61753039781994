@if (status()) {
    <ds-tag [tone]="tone()">
    @switch (status()) {
        @case (orderStatusEnum.PENDING) {
            Disponierbar ({{assignedJobCount()}}/{{totalJobCount()}})
        }
        @case (orderStatusEnum.READY) {
            Vorbereitet
        }
        @case (orderStatusEnum.COMPLETED) {
            Abgeschlossen
        }
        @default {
            Undefiniert
        }
    }
    </ds-tag>    
}