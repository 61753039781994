import { AG_GRID_LOCALE_DE } from '@ag-grid-community/locale';
import { Component, Input, input, signal } from '@angular/core';
import { DsButtonModule, DsFormFieldModule, DsLabelModule, DsLoadingAreaModule, DsTagModule, DsToastModule, } from '@bmw-ds/components';

import { AgGridAngular } from '@ag-grid-community/angular';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ColDef, DateFilterModel, FilterModel, GetRowIdFunc, GetRowIdParams, GridApi, GridReadyEvent, IRowNode, ModuleRegistry, ProcessCellForExportParams, SizeColumnsToContentStrategy } from '@ag-grid-community/core';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Trackable } from '@bmw-spp/bmw-spp-frontend-common';
import { VehicleNavigationCellRendrerComponent } from '../vehicle-navigation-cell-rendrer/vehicle-navigation-cell-rendrer.component';
import GridExportEvent from '../vehicles/grid-export-event';

ModuleRegistry.registerModules([ClientSideRowModelModule, RowGroupingModule, ColumnsToolPanelModule, StatusBarModule, MenuModule, SetFilterModule]);

@Component({
  selector: 'app-vehicle-ag-grid',
  standalone: true,
  imports: [
    AgGridAngular,
    CommonModule,
    DsButtonModule,
    DsToastModule,
    DsLoadingAreaModule,
    DsFormFieldModule,
    DsLabelModule,
    DsTagModule,
    FormsModule,
  ],
  templateUrl: './vehicle-ag-grid.component.html',
  styleUrl: './vehicle-ag-grid.component.scss'
})
export class VehicleAgGridComponent {
  public gridApi!: GridApi;
  vehicles = input.required<Trackable[]>();
  localeText = AG_GRID_LOCALE_DE;





  _textFilter: string = '';
  @Input()
  public set textFilter(textFilter: string) {
    if (this.gridApi) {
      this._textFilter = textFilter;
      this.gridApi.onFilterChanged();
    }
  }
  get textFilter(): string {
    return this._textFilter;
  }

  constructor(
    private gridExportEvent: GridExportEvent,
  ) {
    this.exportGridToCsv = this.exportGridToCsv.bind(this);
    this.isExternalFilterPresent = this.isExternalFilterPresent.bind(this);
    this.doesExternalFilterPass = this.doesExternalFilterPass.bind(this);
    this.gridExportEvent.getEventEmitter().subscribe(this.exportGridToCsv);
  }

  private exportGridToCsv(): void {
    if (this.gridApi) {
      this.gridApi.exportDataAsCsv({
        processCellCallback: (params: ProcessCellForExportParams) => {
          return params.value;
        }
      });
    }
  }

  columns = signal<ColDef<Trackable>[]>([
    {
      field: 'vehicle.vehicleLicensePlate',
      minWidth: 400,
      headerName: 'Kennzeichen',
    },
    {
      field: 'vehicle.vehicleVisitReason',
      minWidth: 400,
      headerName: 'Grund',
    },
    {
      field: 'vehicle.customerName',
      minWidth: 400,
      headerName: 'Kunde',
    },
    {
      field: 'vehicle.vehicleModel',
      minWidth: 400,
      headerName: 'Model',

    },
    {
      headerName: 'Navigation',
      minWidth: 400,
      cellRenderer: VehicleNavigationCellRendrerComponent,
      sortable: false,
      filter: false,
    },
  ]);

  public selectedGridRow = signal<IRowNode<Trackable> | null>(null);

  defaultColDef = signal<ColDef>({
    sortable: true,
    filter: true,
    suppressNavigable: true,
    editable: false,
  });

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;

    const dateString = new Date(Date.now()).toISOString();
    const filterModel: DateFilterModel = {
      dateFrom: dateString,
      type: 'equals',
      dateTo: null
    }
    this.gridApi.setFilterModel({
      arrivalDatetime: filterModel
    });

    this.gridApi.onFilterChanged();
  }

  isExternalFilterPresent(): boolean {
    return this.textFilter !== "";
  }

  doesExternalFilterPass(node: IRowNode): boolean {
    let hasTextFilter: boolean = false;
    if (node.data) {
      const nodeVehicleStay: Trackable = node.data as Trackable;
      const licensePlate: string = nodeVehicleStay.vehicle!.vehicleLicensePlate as string;
      const vehicleVisitReason: string = nodeVehicleStay.vehicle!.vehicleVisitReason as string;
      const customerName: string = nodeVehicleStay.vehicle!.customerName as string;
      const vehicleModel: string = nodeVehicleStay.vehicle!.vehicleModel as string;
      if (this.textFilter !== '') {
        hasTextFilter =
          licensePlate.toLowerCase().includes(this.textFilter.toLowerCase()) ||
          licensePlate.toLowerCase().includes(this.textFilter.toLowerCase()) ||
          vehicleVisitReason.toLowerCase().includes(this.textFilter.toLowerCase()) ||
          customerName.toLowerCase().includes(this.textFilter.toLowerCase()) ||
          vehicleModel.toLowerCase().includes(this.textFilter.toLowerCase());
      }
      return hasTextFilter;
    }
    return true;
  }
  autoSizeStrategy: SizeColumnsToContentStrategy = {
    type: 'fitCellContents',
  }

  public dismissFilterForColumn(item: FilterModel) {
    this.gridApi.setColumnFilterModel(item['key'], null);
    this.gridApi.onFilterChanged();
  }

  public resetAllFilters() {
    this.gridApi.setFilterModel(null);
    this.gridApi.onFilterChanged();
  }

  public isDateFilterModel(item: DateFilterModel) {
    return 'dateFrom' in item;
  }

  public getColumnHeader(columnId: string) {
    const colDef = this.gridApi.getColumn(columnId);
    return colDef ? colDef.getColDef().headerName : columnId;
  }

  public getFormattedDate(dateString: string) {
    return new Date(dateString).toLocaleDateString([], {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  }

  public paginationPageSize = 50;
  public paginationPageSizeSelector: number[] | boolean = [25, 50, 100, 200, 500, 1000];


  public getRowId: GetRowIdFunc = (params: GetRowIdParams<Trackable>) => {
    let rowID = "";
    if (params && params.data && params.data.vehicle) {
      rowID = params.data.vehicle!.vehicleLicensePlate ?? '';
    }
    return rowID
  }
}