import { Component } from '@angular/core';
import { DsTabsModule } from '@bmw-ds/components';
import { ServiceOrderListComponent } from "../service-order-list/service-order-list.component";
import { VehiclesComponent } from "../vehicles/vehicles.component";

@Component({
  selector: 'app-asset',
  standalone: true,
  imports: [
    DsTabsModule,
    ServiceOrderListComponent,
    VehiclesComponent
],
  templateUrl: './asset-table.component.html',
  styleUrl: './asset-table.component.scss'
})
export class AssetTableComponent {

}
