import { Routes } from '@angular/router';
import { AuthGuard, PageNotFoundComponent } from '@bmw-spp/bmw-spp-frontend-common';
import { environment } from '../environments/environment';
import { AssetTableComponent } from './asset-table/asset-table.component';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { ServiceOrderListComponent } from './service-order-list/service-order-list.component';

export const routes: Routes = [
    { path: 'asset', component: AssetTableComponent, canActivate: environment.authEnabled ? [AuthGuard] : []},
    { path: 'service-orders', component: ServiceOrderListComponent, canActivate: environment.authEnabled ? [AuthGuard] : []},
    { path: 'vehicles', component: VehiclesComponent, canActivate: environment.authEnabled ? [AuthGuard] : [] },
    { path: '',   redirectTo: '/asset', pathMatch: 'full' },
    { path: '**', component: PageNotFoundComponent }

];

