import { IAstEnvironment } from "./ienvironment";
import { baseBackendEnvironment } from "@bmw-spp/bmw-spp-frontend-common";

export const environment: IAstEnvironment = {
  ...baseBackendEnvironment,
  environmentName: 'Dev',
  apiUrl: 'https://bmw-ast-gateway.c2-cloud.de/api/',
  wsUrl: 'wss://spp-backend.c2-cloud.de/api/',
  providers: [
    ...baseBackendEnvironment.providers,
  ],
  authEnabled: true,
  clientId: "grt-frontend",
  issuer: "https://keycloakspp.c2-cloud.de/realms/spp",
  secureRouteRootUrl: "https://bmw-ast-gateway.c2-cloud.de/api/",
  redirectURL: window.location.origin,

};
