<h1>Fahrzeuge</h1>
<div class="row d-flex flex-row-reverse">
  <!-- <ds-form-field [optionalText]="' '" class="col-11">
    <ds-checkbox-group [isBlock]="true" variant="inline" name="viewSelection">
      <input
        ds-input
        id="toggleGroup"
        type="checkbox"
        name="toggleGroup"
        [value]="true"
        [ngModel]="groupModeIsActive()"
        (ngModelChange)="setGroupModeisActive($event)"
      />
      <label ds-label for="toggleGroup">Gruppierung</label>
      <input
        ds-input
        id="all"
        type="checkbox"
        name="viewSelectionAll"
        [value]="true"
        [ngModel]="viewSelectionAll()"
        (ngModelChange)="setViewSelectionAll($event)"
      />
      <label ds-label for="all">Alle Fahrzeuge</label>
      <input
        ds-input
        id="onarea"
        type="checkbox"
        name="viewSelectionIsOnArea"
        [value]="true"
        [ngModel]="viewSelectionIsOnArea()"
        (ngModelChange)="setViewSelectionIsOnArea($event)"
      />
      <label ds-label for="onarea">Auf dem Gelände</label>
      <input
        ds-input
        id="divorced"
        type="checkbox"
        name="viewSelectionIsNotMarried"
        [value]="true"
        [ngModel]="viewSelectionIsNotMarried()"
        (ngModelChange)="setViewSelectionIsMarried($event)"
      />
      <label ds-label for="divorced">Nicht verheiratet</label>
    </ds-checkbox-group>
  </ds-form-field> -->

  <!-- <div class="col-11"></div> -->
  <div class="col-1 text-end">
    <button
      type="button"
      ds-button
      variant="ghost"
      icon="download"
      (click)="exportGridToCsv()"
      aria-label="Download"
    >
      <span></span>
    </button>
    <button
      ds-button
      [ds-popover-trigger-for]="popoverId"
      variant="ghost"
      aria-label="Tabelle filtern"
    >
      <ds-icon icon="filter"></ds-icon>
    </button>
    <ds-popover #popoverId>
      <ds-box-content>
        <ds-form-field>
          <label ds-label for="textFilter">Freitext-Suche</label>
          <input
            ds-input
            type="text"
            id="textFilter"
            placeholder="Kennzeichen, Kundeninformationen (Name, Firma), Fahrzeug-Kommentar, VIN"
            [(ngModel)]="textFilter"
          />
        </ds-form-field>
      </ds-box-content>
    </ds-popover>
  </div>
</div>
<div class="row" ds-loading-area-container>
  <div class="col-12">
    <ds-box>
      <ds-box-content>
        <app-vehicle-ag-grid
          [vehicles]="vehicle()"
          [textFilter]="textFilter()"
        ></app-vehicle-ag-grid>
      </ds-box-content>
    </ds-box>
  </div>
</div>
