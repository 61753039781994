import { Component, OnDestroy, ViewChild, computed, effect, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DsBoxModule, DsButtonModule, DsCheckboxModule, DsFormFieldModule, DsIconModule, DsLoadingAreaConfiguration, DsLoadingAreaModule, DsLoadingAreaOverlayContainerDirective, DsLoadingAreaService, DsPopoverModule, DsSelectModule, DsToastModule, DsToastService } from '@bmw-ds/components';
import { TrackableService } from '@bmw-spp/bmw-spp-frontend-common';
import { VehicleAgGridComponent } from '../vehicle-ag-grid/vehicle-ag-grid.component';
import GridExportEvent from './grid-export-event';

@Component({
  selector: 'app-vehicles',
  standalone: true,
  imports: [
    DsToastModule,
    DsLoadingAreaModule,
    DsBoxModule,
    DsIconModule,
    DsPopoverModule,
    DsSelectModule,
    VehicleAgGridComponent,

    DsToastModule,
    DsLoadingAreaModule,
    DsCheckboxModule,
    DsFormFieldModule,
    FormsModule,
    DsButtonModule
  ],
  templateUrl: './vehicles.component.html',
  styleUrl: './vehicles.component.scss'
})
export class VehiclesComponent implements OnDestroy {
  isLoading = signal(true);
  viewSelectionAll = signal(true);
  viewSelectionIsNotMarried = signal(false);
  viewSelectionIsOnArea = signal(false);
  groupModeIsActive = signal(false);
  textFilter = signal('');

  vehicle = computed(() => {
    const data = this.trackableService.data();

    return data;
  });

  @ViewChild(DsLoadingAreaOverlayContainerDirective)
  loadingAreaContainer!: DsLoadingAreaOverlayContainerDirective;

  private static instance = 0;
  private loadingAreaID = `VehiclesAllComponent-${VehiclesComponent.instance++}`;

  private loadingAreaConfig: DsLoadingAreaConfiguration = {
    id: this.loadingAreaID,
    container: this.loadingAreaContainer,
  }

  constructor(
    private trackableService: TrackableService,
    private toastService: DsToastService,
    private loadingAreaService: DsLoadingAreaService,
    private gridExportEvent: GridExportEvent,
  ) {
    effect(() => {
      if (this.isLoading()) {
        this.loadingAreaService.addLoadingArea(this.loadingAreaConfig);
      } else {
        this.loadingAreaService.removeLoadingArea(this.loadingAreaID);
      }
    });
    this.loadData();
  }

  async loadData() {
    try {
      await this.trackableService.loadAll();
    } catch (err) {
      this.toastService.pushToast({
        id: 'error-toast',
        tone: 'critical',
        toastText: 'Fehler beim Laden. Bitte versuchen Sie es später erneut.'
      })
    } finally {
      this.isLoading.set(false);
    }
  }

  ngOnDestroy(): void {
    this.toastService.clearToasts();
  } public showPairingDialog = signal(false);

  public exportGridToCsv(): void {
    this.gridExportEvent.emitEvent({});
  }
}
