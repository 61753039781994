import { Component, signal } from '@angular/core';
import { DsButtonModule } from '@bmw-ds/components';
import { ICellRendererParams } from '@ag-grid-community/core';
import { VehicleStay } from '@bmw-spp/bmw-spp-frontend-common';
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-vehicle-navigation-cell-rendrer',
  standalone: true,
  imports: [DsButtonModule],
  templateUrl: './vehicle-navigation-cell-rendrer.component.html',
  styleUrl: './vehicle-navigation-cell-rendrer.component.scss'
})
export class VehicleNavigationCellRendrerComponent {
  public params = signal<ICellRendererParams<VehicleStay> | undefined>(undefined);

  href = environment.environmentName === "Dev" ?
    "https://map-frontend.c2-cloud.de?trackable=05835d51-a755-4f26-b678-1c8705d387ec" :
    `map-frontend.apps.4wm-spp-dev.eu-central-1.aws.cloud.bmw?trackable=1159f862-f1e3-4dd6-9221-9802370331ba`;
  agInit(params: ICellRendererParams): void {
    this.params.set(params);
  }

  refresh(params: ICellRendererParams): boolean {
    this.params.set(params);
    return true;
  }
}
