<h1>Asset tabelle</h1>
<ds-tabs>
  <ds-tabs-label for="a">Serviceaufträge</ds-tabs-label>
  <ds-tabs-content id="a" class="row w-100">
    <app-service-order-list></app-service-order-list>
  </ds-tabs-content>

  <ds-tabs-label for="b">Fahrzeuge</ds-tabs-label>
  <ds-tabs-content id="b" class="row w-100">
    <app-vehicles></app-vehicles>
  </ds-tabs-content>

</ds-tabs>
