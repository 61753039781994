<!-- @if (params()?.data?.id) { -->

<a
  ds-button
  variant="outline"
  icon="map"
  target="_blank"
  size="sm"
  [href]="href"
>
  Anzeigen
</a>

<!-- } -->
