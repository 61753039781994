<h1>Serviceaufträge</h1>

<div class="row mt-3x">
  <div class="col-12">
    <ds-box>
      <ds-box-header>
        <div class="row">
          <div class="col">Liste der Serviceaufträge</div>
          <div class="col-3">
            <div class="text-end">
              <ds-form-field>
                <ds-icon icon="search" size="md"></ds-icon>
                <input
                  ds-input
                  id="searchbox"
                  type="search"
                  placeholder="Suche"
                  [ngModel]="searchFilterText()"
                  (ngModelChange)="updateSearchFilter($event)"
                />
              </ds-form-field>
            </div>
          </div>
        </div>
      </ds-box-header>
      <ds-box-content>
        <div class="row">
          <div class="col-12">
            <form>
              <div class="filter-row">
                <div class="filter-col">
                  <ds-form-field optionalText=" ">
                    <ds-checkbox-group [isBlock]="true" variant="inline">
                      <input
                        ds-input
                        name="pending"
                        type="checkbox"
                        [ngModel]="activeFilters()[orderStatus.PENDING]"
                        (ngModelChange)="
                          updateActiveFilters(orderStatus.PENDING, $event)
                        "
                        [id]="uniqueIdId + '-filter-' + orderStatus.PENDING"
                        [value]="true"
                      />
                      <label
                        ds-label
                        ds-badge
                        [badgeCounter]="newOrderCount()"
                        badgeTone="info"
                        badgeLabel="Anzahl neu"
                        [for]="uniqueIdId + '-filter-' + orderStatus.PENDING"
                        >Disponierbar ({{
                          orderCount()[orderStatus.PENDING]
                        }})</label
                      >
                      <input
                        ds-input
                        name="ready"
                        type="checkbox"
                        [ngModel]="activeFilters()[orderStatus.READY]"
                        (ngModelChange)="
                          updateActiveFilters(orderStatus.READY, $event)
                        "
                        [id]="uniqueIdId + '-filter-' + orderStatus.READY"
                        [value]="true"
                      />
                      <label
                        ds-label
                        [for]="uniqueIdId + '-filter-' + orderStatus.READY"
                        >Vorbereitet ({{
                          orderCount()[orderStatus.READY]
                        }})</label
                      >
                      <input
                        ds-input
                        name="completed"
                        type="checkbox"
                        [ngModel]="activeFilters()[orderStatus.COMPLETED]"
                        (ngModelChange)="
                          updateActiveFilters(orderStatus.COMPLETED, $event)
                        "
                        [id]="uniqueIdId + '-filter-' + orderStatus.COMPLETED"
                        [value]="true"
                      />
                      <label
                        ds-label
                        [for]="uniqueIdId + '-filter-' + orderStatus.COMPLETED"
                        >Auftrag abgeschlossen ({{
                          orderCount()[orderStatus.COMPLETED]
                        }})</label
                      >
                    </ds-checkbox-group>
                  </ds-form-field>
                </div>
                <div class="filter-col">
                  <button
                    ds-button
                    variant="ghost-muted"
                    type="button"
                    (click)="resetFilters()"
                  >
                    zurücksetzen
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div class="col-12">
            <ag-grid-angular
              class="ag-theme-density spp-grid"
              domLayout="normal"
              [rowData]="orders()"
              [columnDefs]="columns()"
              [gridOptions]="gridOptions()"
              [autoSizeStrategy]="autoSizeStrategy()"
              [defaultColDef]="defaultColDef()"
              [paginationAutoPageSize]="true"
              [paginationPageSizeSelector]="paginationPageSizeSelector"
              [pagination]="true"
              [localeText]="localeText"
              [getRowId]="getRowId"
              rowClass="order-row"
              [rowClassRules]="rowClassRules()"
              [rowSelection]="rowSelection()"
              (rowSelected)="onRowSelected($event)"
            >
            </ag-grid-angular>
          </div>
        </div>
      </ds-box-content>
    </ds-box>
  </div>
</div>
