import { AgGridAngular } from '@ag-grid-community/angular';
import { AG_GRID_LOCALE_DE } from '@ag-grid-community/locale';
import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { DsBoxModule, DsToastService, DsFormFieldModule, DsCheckboxModule, DsButtonModule, DsBadgeModule, DsIconModule } from '@bmw-ds/components';
import { ColDef, GetRowIdFunc, GetRowIdParams, GridOptions, ModuleRegistry, RowClassRules, RowSelectedEvent, RowSelectionOptions, SizeColumnsToContentStrategy } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { OrderStatusCellRendererComponent } from '../order-status-cell-renderer/order-status-cell-renderer.component';
import { FormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
import { Order, OrderFunctionsService, OrderService, OrderStatus } from '@bmw-spp/bmw-spp-frontend-common';
import { DispatchCellButtonRendererComponent } from '../dispatch-cell-button-renderer/dispatch-cell-button-renderer.component';
import debug from 'debug';

ModuleRegistry.registerModules([ClientSideRowModelModule, StatusBarModule, ColumnsToolPanelModule, SetFilterModule, MenuModule, FiltersToolPanelModule]);

@Component({
  selector: 'app-service-order-list',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    DsBoxModule,
    DsButtonModule,
    AgGridAngular,
    DsFormFieldModule,
    DsCheckboxModule,
    DsBadgeModule,
    DsFormFieldModule,
    DsIconModule,

  ],
  templateUrl: './service-order-list.component.html',
  styleUrl: './service-order-list.component.scss'
})
export class ServiceOrderListComponent implements OnInit {
  private logDebug = debug('app:ServiceOrderListComponent:log');
  private logError = debug('app:ServiceOrderListComponent:error*');


  public localeText = AG_GRID_LOCALE_DE;

  public orderStatus = OrderStatus;

  public activeFilters = signal<{
    [key in OrderStatus]?: boolean
  }>({});

  private static lastId = 0;

  public uniqueIdId = `ServiceOrderListComponent-${ServiceOrderListComponent.lastId++}`;

  private orderService = inject(OrderService);
  private toastService = inject(DsToastService);
  private orderFunctionsService = inject(OrderFunctionsService);

  constructor() {
    this.logDebug.log = console.log.bind(console);
    this.logError.log = console.error.bind(console);
  }

  async ngOnInit() {
    try {
      await this.orderService.loadAll();
    }
    catch (err) {
      this.logError('error: %O', err)
      this.toastService.pushToast({
        id: 'error-toast',
        tone: 'critical',
        toastText: 'Fehler beim Laden der Serviceaufträge.'
      });
    }
  }

  public orders = computed<Order[]>(() => {
    const data = this.orderService.data();
    const allActiveFilters = this.activeFilters();
    const activeFilters = Object.entries(allActiveFilters).filter(([, value]) => value);
    const searchFilterText = this.searchFilterText();

    const filteredData = data.filter(order => {
      const currentOrderStatus = this.orderFunctionsService.getStatus(order);
      let hasActiveStatus = activeFilters.length === 0;
      for (const [key, value] of Object.entries(allActiveFilters)) {
        if (key !== currentOrderStatus) {
          continue;
        }
        if (value) {
          hasActiveStatus = true;
        }
      }

      let searchFilterTextFound: boolean | undefined = true;
      if (searchFilterText) {
        const lowerCaseSearchText = searchFilterText.toLowerCase();
        searchFilterTextFound = order.passingCustomerInformation?.lastName?.toLowerCase().includes(lowerCaseSearchText)
          || order.passingCustomerInformation?.firstName?.toLowerCase().includes(lowerCaseSearchText)
          || order.orderNumber.toLowerCase().includes(lowerCaseSearchText)
          || order.serviceAdvisor?.toLowerCase().includes(lowerCaseSearchText);
      }

      return hasActiveStatus && searchFilterTextFound;
    });
    return filteredData;
  });

  public orderCount = computed<{
    [key in OrderStatus]?: number
  }>(() => {
    const data = this.orderService.data();
    const orderCount: {
      [key in OrderStatus]: number
    } = {
      [OrderStatus.COMPLETED]: 0,
      [OrderStatus.PENDING]: 0,
      [OrderStatus.READY]: 0,
    };
    for (const order of data) {
      const currentOrderStatus = this.orderFunctionsService.getStatus(order);
      let value = orderCount[currentOrderStatus] ?? 0;
      orderCount[currentOrderStatus] = ++value;
    }
    return orderCount;
  });

  public newOrderCount = computed<number>(() => {
    const data = this.orderService.data();
    let orderCount = 0;
    for (const order of data) {
      const isNew = this.orderFunctionsService.getIsNew(order);
      if (!isNew) {
        continue;
      }
      orderCount++;
    }
    return orderCount;
  });

  public autoSizeStrategy = signal<SizeColumnsToContentStrategy>({
    type: 'fitCellContents',
  });

  public columns = signal<ColDef<Order>[]>([
    {
      headerName: 'Auftrag',
      field: 'orderNumber',
      valueFormatter: v => `${v.value}`,
    },
    {
      headerName: 'Kunde',
      valueGetter: v => `${v.data?.passingCustomerInformation?.firstName ?? ''} ${v.data?.passingCustomerInformation?.lastName ?? ''}`,
    },
    {
      headerName: 'AW',
      valueGetter: v => v.data ? this.orderFunctionsService.getOrderAW(v.data) : 0,
    },
    {
      headerName: 'Status',
      valueGetter: v => v.data ? this.orderFunctionsService.getStatus(v.data) : undefined,
      cellRenderer: OrderStatusCellRendererComponent,
      sort: "asc",
      comparator: (valueA: OrderStatus, valueB: OrderStatus, nodeA, nodeB) => {
        const sortOrder: {
          [key in OrderStatus]: number } = {
          [OrderStatus.PENDING]: 0,
          [OrderStatus.READY]: 1,
          [OrderStatus.COMPLETED]: 2,
        }
        if (!nodeA.data || !nodeB.data) {
          return 0;
        }

        let orderValueA = valueA ? sortOrder[valueA] : 0;
        let orderValueB = valueB ? sortOrder[valueB] : 0;

        const orderAIsNew = this.orderFunctionsService.getIsNew(nodeA.data);
        if (orderAIsNew) {
          orderValueA = -1;
        }
        const orderBIsNew = this.orderFunctionsService.getIsNew(nodeB.data);
        if (orderBIsNew) {
          orderValueB = -1;
        }

        if (orderValueA === orderValueB) return 0;
        return (orderValueA > orderValueB) ? 1 : -1;
      }
    },
    {
      headerName: 'Arbeitspaket',
      valueGetter: v => v.data && this.orderFunctionsService.getStatus(v.data) !== OrderStatus.PENDING ? this.orderFunctionsService.getTotalJobCount(v.data) : undefined,
      valueFormatter: v => v.data && this.orderFunctionsService.getStatus(v.data) !== OrderStatus.PENDING ? `${this.orderFunctionsService.getTotalJobCount(v.data)}` : "", // todo: complete
    },
    {
      headerName: 'Disposition',
      field: 'orderDistribution.dispositionDate',
      valueFormatter: v => v.data?.orderDistribution?.dispositionDate?.toLocaleDateString() ?? "",
    },
    {
      headerName: 'Erstellzeit',
      field: 'creationDate',
      valueFormatter: v => v.data?.creationDate ? v.data?.creationDate?.toLocaleDateString() : "",
    },
    {
      headerName: 'Serviceberater',
      field: 'serviceAdvisor',
    },
    {
      sortable: false,
      filter: false,
      suppressHeaderMenuButton: true,
      suppressColumnsToolPanel: true,
      cellRenderer: DispatchCellButtonRendererComponent,
      onCellClicked:()=>{
        this.onDispatch();
      }
    },
  ]);
  onDispatch(){
    console.log('selected:', this.selectedOrder());
    
  }
  public gridOptions = signal<GridOptions>({
    statusBar: {
      statusPanels: [
        // { statusPanel: 'agTotalAndFilteredRowCountComponent' },
        { statusPanel: 'agTotalRowCountComponent' },
        // { statusPanel: 'agFilteredRowCountComponent' },
        // { statusPanel: 'agSelectedRowCountComponent' },
        // { statusPanel: 'agAggregationComponent' }
      ]
    },
    sideBar: {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",

          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressColumnFilter: true,
            suppressColumnSelectAll: true,
          },
        },
        "filters",
      ],
      defaultToolPanel: "",
    }
  });

  public defaultColDef = signal<ColDef>({
    sortable: true,
    filter: true,
    suppressNavigable: true,
    editable: false,
  });

  public paginationPageSizeSelector: number[] | boolean = [6, 10, 25, 50, 100, 200, 500, 1000];

  public getRowId: GetRowIdFunc = (params: GetRowIdParams<Order>) => params.data.orderNumber;

  public updateActiveFilters(orderStatus: OrderStatus, newValue: boolean) {
    const activeFilters = this.activeFilters();
    const oldValue = activeFilters[orderStatus];
    // for some reason ngModelChange is called 3 times by ds-input checkboxes.
    // prevent multiple filter recalculations through ensuring the value has been changed
    if (oldValue === newValue) {
      return;
    }
    // clone needed for recalculation of computed signals
    const clone = structuredClone(activeFilters);
    clone[orderStatus] = newValue;
    this.activeFilters.set(clone);
  }

  public searchFilterText = signal<string | undefined>(undefined);

  public updateSearchFilter(searchFilterText: string) {
    this.searchFilterText.set(searchFilterText.toLowerCase());
  }

  public resetFilters() {
    this.activeFilters.set({});
  }

  public rowClassRules = signal<RowClassRules<Order>>({
    'new-order-row': (p) => p.data ? this.orderFunctionsService.getIsNew(p.data) : false,
  });

  public rowSelection = signal<RowSelectionOptions>({
    mode: 'singleRow',
    checkboxes: false,
    enableClickSelection: 'enableSelection',
  });

  public selectedOrder = signal<Order | undefined>(undefined);

  public onRowSelected(event: RowSelectedEvent<Order>) {
    if (event.node.isSelected()) {
      this.selectedOrder.set(event.node.data);
    }
  }

}
